exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-architectural-projects-beach-resort-index-js": () => import("./../../../src/pages/architectural-projects/beach-resort/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-beach-resort-index-js" */),
  "component---src-pages-architectural-projects-cave-house-interior-index-js": () => import("./../../../src/pages/architectural-projects/cave-house-interior/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-cave-house-interior-index-js" */),
  "component---src-pages-architectural-projects-contemporary-living-area-index-js": () => import("./../../../src/pages/architectural-projects/contemporary-living-area/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-contemporary-living-area-index-js" */),
  "component---src-pages-architectural-projects-coworking-space-index-js": () => import("./../../../src/pages/architectural-projects/coworking-space/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-coworking-space-index-js" */),
  "component---src-pages-architectural-projects-gialos-house-index-js": () => import("./../../../src/pages/architectural-projects/gialos-house/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-gialos-house-index-js" */),
  "component---src-pages-architectural-projects-gym-interior-index-js": () => import("./../../../src/pages/architectural-projects/gym-interior/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-gym-interior-index-js" */),
  "component---src-pages-architectural-projects-hotel-restaurant-index-js": () => import("./../../../src/pages/architectural-projects/hotel-restaurant/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-hotel-restaurant-index-js" */),
  "component---src-pages-architectural-projects-house-bedrooms-interior-index-js": () => import("./../../../src/pages/architectural-projects/house-bedrooms-interior/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-house-bedrooms-interior-index-js" */),
  "component---src-pages-architectural-projects-index-js": () => import("./../../../src/pages/architectural-projects/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-index-js" */),
  "component---src-pages-architectural-projects-munchen-villa-index-js": () => import("./../../../src/pages/architectural-projects/munchen-villa/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-munchen-villa-index-js" */),
  "component---src-pages-architectural-projects-office-building-lobby-index-js": () => import("./../../../src/pages/architectural-projects/office-building-lobby/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-office-building-lobby-index-js" */),
  "component---src-pages-architectural-projects-pond-house-interior-index-js": () => import("./../../../src/pages/architectural-projects/pond-house-interior/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-pond-house-interior-index-js" */),
  "component---src-pages-architectural-projects-school-exterior-index-js": () => import("./../../../src/pages/architectural-projects/school-exterior/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-school-exterior-index-js" */),
  "component---src-pages-architectural-projects-villa-interior-design-index-js": () => import("./../../../src/pages/architectural-projects/villa-interior-design/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-villa-interior-design-index-js" */),
  "component---src-pages-architectural-projects-vr-villa-interior-design-index-js": () => import("./../../../src/pages/architectural-projects/vr/villa-interior-design/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-vr-villa-interior-design-index-js" */),
  "component---src-pages-architectural-projects-warehouse-exterior-index-js": () => import("./../../../src/pages/architectural-projects/warehouse-exterior/index.js" /* webpackChunkName: "component---src-pages-architectural-projects-warehouse-exterior-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-vr-js": () => import("./../../../src/pages/vr.js" /* webpackChunkName: "component---src-pages-vr-js" */)
}

